$(document).foundation();
// var waypoints = $('.heres-how li').waypoint({
//   	handler: function(direction) {
// 		if(direction == 'down'){
// 			$(this.element).addClass('mui-enter-active');
// 		} else {
// 			$(this.element).removeClass('mui-enter-active');
// 		}
//   	},
//   	offset: '75%'
// });
var separator = $('.separator').waypoint({
  	handler: function(direction) {
		if(direction == 'down'){
			$(this.element).addClass('mui-leave-active');
		} else {
			$(this.element).removeClass('mui-leave-active');
		}
  	},
  	offset: '75%'
});